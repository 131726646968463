import axios from "axios";
import React from "react";

export class successForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    this.cityEndpoint = apiUrl + "/city/id";
    this.agentEndpoint = apiUrl + "/agent/";
    this.propertyEndpoint = apiUrl + "/property/";
    this.imagesEndpoint = apiUrl + "/property/images";
    this.logoEndpoint = apiUrl + "/agent/image";
  }
  async postAgent(completeData) {
    this.down_payment = completeData.down_payment;
    let agentData = {
      first_name: completeData["agent"],
      last_name: "",
      email: completeData["email"],
      user_type_id: "2",
      phone: completeData["phone"],
      logo_location:
        "https://toperty-marketplace.s3.amazonaws.com/agents/7.jpeg",
    };

    const propId = await axios
      .post(this.agentEndpoint, agentData)
      .then((response) => {
        this.agentId = response.data.id;
      });
    return propId;
  }

  async postProperty(completeData) {
    let nowDate = new Date();
    const cityId = await axios.get(
      this.cityEndpoint + "?city_name=" + completeData.city
    );
    const amenities = {
      lift: completeData["amenities"].includes("Ascensor"),
      residential_security: completeData["amenities"].includes("Seguridad"),
      private_deposit: completeData["amenities"].includes("Depósito"),
      gym: completeData["amenities"].includes("Gimnasio"),
      terrace: completeData["amenities"].includes("Terraza"),
      community_room: completeData["amenities"].includes("Salón comunal"),
      visitor_parking: completeData["amenities"].includes(
        "Parqueadero visitantes"
      ),
      urban_green_spaces: completeData["amenities"].includes("Zonas verdes"),
      laundry_room: completeData["amenities"].includes("Lavandería"),
      childrens_area: completeData["amenities"].includes("Parque infantil"),
      pool: completeData["amenities"].includes("Piscina"),
      sauna: completeData["amenities"].includes("Sauna"),
      squash_court: completeData["amenities"].includes("cancha de squash"),
    };

    let newPropertyData = {
      agent_id: this.agentId,
      city_id: cityId.data.id,
      prop_type_id: 2,
      creation_date: nowDate.toISOString(),
      latitude: "",
      longitude: "",
      bathrooms: completeData["bathrooms"],
      bedrooms: completeData["rooms"],
      livingarea: completeData["private_area"],
      total_area: completeData["living_area"],
      rent_to_own_price: 0,
      market_value: completeData["cost"],
      description: completeData["description"],
      images_location: "",
      images_number: completeData["images_number"],
      economic_classification: completeData["economic_class"],
      parking_spaces: completeData["parkings"],
      address: completeData["address"],
      floor: completeData["floor"],
      administration_cost: "",
      antiquity: completeData["construction_date"],
      lift: amenities["lift"],
      terrace: amenities["terrace"],
      residential_security: amenities["residential_security"],
      private_deposit: amenities["private_deposit"],
      gym: amenities["gym"],
      community_room: amenities["community_room"],
      visitor_parking: amenities["visitor_parking"],
      urban_green_spaces: amenities["urban_green_spaces"],
      laundry_room: amenities["laundry_room"],
      childrens_area: amenities["childrens_area"],
      pool: amenities["pool"],
      sauna: amenities["sauna"],
      squash_court: amenities["squash_court"],
      min_principal_payment: 0,
      administration_fee: completeData["administration"],
    };
    await axios
      .post(this.propertyEndpoint, newPropertyData)
      .then((response) => {
        this.propertyId = response.data.id;
      });
  }
  async postImages(completeData) {
    let imagesData = completeData["photos"].map((data) => data.image_url);
    await axios.post(
      this.imagesEndpoint + "?property_id=" + this.propertyId,
      imagesData
    );
  }
  async postAgentLogo(completeData) {
    await axios.post(this.logoEndpoint, null, {
      params: {
        image: completeData["logo"],
        agent_id: this.agentId,
      },
    });
  }
  async main(data) {
    await this.postAgent(data);
    await this.postAgentLogo(data);
    await this.postProperty(data);
    await this.postImages(data);
    window.location.href = "/success";
  }
}
