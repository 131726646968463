import React from "react";
import { Col, Row } from "react-bootstrap";
import NumberFormat from "react-number-format";

export function Input(props) {
	const formControl = () => {
		const handleFormData = props.handleFormData;
		const key = props.inputKey;
		if (props.inputType) {
			if (
				props.inputType === "text" ||
				props.inputType === "mail"
			) {
				return (
					<input
						autoFocus
						className="form-control"
						placeholder={props.inputPlaceholder}
						type={props.inputType}
						id={props.inputId}
						value={props.inputValue}
						onChange={(e) =>
							handleFormData(key, e.target.value)
						}
					/>
				);
			} else if (props.inputType === "textarea") {
				return (
					<textarea
						className="form-control"
						name={props.inputId}
						id={props.inputId}
						value={props.inputValue}
						rows="10"
						onChange={(e) =>
							handleFormData(key, e.target.value)
						}
					></textarea>
				);
			} else if (props.inputType === "select") {
				return (
					<select
						name={props.inputId}
						id={props.inputId}
						className="form-select"
						value={props.inputValue}
						onChange={(e) =>
							handleFormData(key, e.target.value)
						}
					>
						<option value="0" key="0">
							Selecciona...
						</option>

						{props.select.map((value, index) => (
							<option value={value} key={index}>
								{value}
							</option>
						))}
					</select>
				);
			} else if (props.inputType === "radio") {
				return (
					<div>
						{props.radio.map((value, index) => (
							<Col md={12} className="job-box">
								<input
									type="radio"
									name="group-1"
									id={index}
									className="form-check-input"
									value={value}
									onChange={(e) =>
										handleFormData(key, e.target.value)
									}
									checked={value === props.formData[key]}
								/>
								<label
									htmlFor={index}
									className="form-check-label mr-2"
								>
									{value}
								</label>
							</Col>
						))}
					</div>
				);
			} else if (props.inputType === "money") {
				const handleChangePrices = (values) => {
					const { value } = values;
					handleFormData(
						key,
						parseInt(value.split(".").join(""))
					);
				};
				return (
					<NumberFormat
						thousandSeparator={true}
						prefix={"$"}
						id={props.inputId}
						onValueChange={handleChangePrices}
						value={props.inputValue}
						className="form-control"
						placeholder={props.placeholder}
					/>
				);
			} else if (props.inputType === "percentage") {
				const handleChangePercentages = (values) => {
					const { value } = values;
					handleFormData(
						key,
						parseFloat(value.split("%")[0])
					);
				};
				return (
					<NumberFormat
						thousandSeparator={false}
						suffix={"%"}
						id={props.inputId}
						onValueChange={handleChangePercentages}
						value={props.inputValue}
						className="form-control"
						placeholder={props.placeholder}
					/>
				);
			} else if (props.inputType === "checkbox") {
				return (
					<div className="form-check">
						{props.checkbox.map((value, index) => (
							<Col md={12} className="form-check">
								<input
									className="form-check-input"
									type="checkbox"
									id={index}
									name="group 1"
									key={index}
									value={value}
									onChange={(e) =>
										handleFormData(key, e.target.value)
									}
									checked={value === props.formData[key]}
								/>
								<label
									htmlFor={index}
									className="form-check-label"
								>
									{value}
								</label>
							</Col>
						))}
					</div>
				);
			} else if (props.inputType === "multiCheckbox") {
				return (
					<div className="form-check">
						{props.checkbox.map((value, index) => (
							<Col md={12} className="form-check">
								<input
									className="form-check-input"
									type="checkbox"
									id={`custom-checkbox-${index}`}
									name={value}
									key={index}
									value={value}
									onChange={(e) => handleFormData(key, e)}
									checked={
										props.formData[key].includes(value)
									}
								/>
								<label
									htmlFor={index}
									className="form-check-label"
								>
									{value}
								</label>
							</Col>
						))}
					</div>
				);
			} else if (props.inputType === "photos") {
				const handleAddInput = props.handleAddInput;
				const handleRemoveInput = props.handleRemoveInput;
				const handleImagesChange = props.handleImagesChange;
				return (
					<>
						{props.formData["photos"].map(
							(singleImages, index) => (
								<div key={index} className="image">
									<div className="first-division">
										<input
											className="form-control img-input"
											name="image_url"
											type="text"
											id="image_url"
											value={singleImages.images}
											onChange={(e) =>
												handleImagesChange(e, index)
											}
											required
										/>
										{props.formData["photos"].length - 1 ===
											index &&
											props.formData["photos"].length <
											20 && (
												<button
													type="button"
													onClick={handleAddInput}
													className="btn-rounded-invert my-3 add-rm"
												>
													<span>Agregar nuevo enlace</span>
												</button>
											)}
									</div>
									<div className="second-division">
										{props.formData["photos"].length !==
											1 && (
												<button
													type="button"
													onClick={() =>
														handleRemoveInput(index)
													}
													className="btn-rounded-invert-danger my-3 add-rm"
												>
													<span>Remover enlace</span>
												</button>
											)}
									</div>
								</div>
							)
						)}
					</>
				);
			}
		} else {
			return <></>;
		}
	};
	return (
		<>
			<Row>
				<Col md={12}>
					{props.title}
					<p className="fs-5 dark-purple">{props.info}</p>
				</Col>
				<Col md={12}>
					<p className="fs-5 dark-purple">
						<b className="fs-4">{props.inputLabel} </b>
					</p>
				</Col>
				<Col md={12} className="mform-form-container">
					{formControl()}
				</Col>
			</Row>
		</>
	);
}
