import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";


export function Success() {
  // ReactPixel.init("1185883698871880");
  ReactPixel.track("CompleteRegistration");
  return (
    <>
      <Container fluid className="p-sm-3 success-container">
        <Row className="align-items-center text-center justify-content-center">
          <Col md={6}>
            <h1 className="supertitle bolder ">Gracias</h1>
            <h3 className="mt-4">
              Revisa tu email para los próximos pasos del proceso de aplicación.
              Si no lo encuentras en la bandeja de entrada, revisa Spam.
            </h3>
          </Col>
        </Row>
      </Container>
    </>
  );
}
