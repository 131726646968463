import React from "react";

function collapse() {
  let center = document.getElementById("navbar-center");
  let icon = document.getElementById("toggler-icon");
  if (center) {
    icon.classList.toggle("active");
    center.classList.toggle("active");
  } else console.log("not-ok");
}
export function Navbarcomp({ nonavpage }) {
  return (
    <>
      <div className={`${nonavpage && "d-none"} nav`}>
        <button className="navbar-toggler" onClick={collapse}>
          <span className="navbar-toggler-icon" id="toggler-icon"></span>
        </button>
        <a className="navbar-brand" href="https://www.toperty.co">
          <div className="brand-image"></div>
        </a>
        <div id="navbar-center" className="navbar-center navbar-collapse">
          <a href="https://www.toperty.co" className="nav-link">
            <p>Nosotros</p>
          </a>
          <a href="https://www.calculadora.toperty.co" className="nav-link">
            <p>Calculadora</p>
          </a>
          <a href="https://www.toperty.co/faq" className="nav-link">
            <p>Preguntas Frecuentes</p>
          </a>
          <a href="https://www.marketplace.toperty.co" className="nav-link">
            <p>Encuentra Tu Casa</p>
          </a>
          <a
            href="https://www.marketplace.toperty.co/form/0/0"
            className="btn-rounded-green"
          >
            Aplica ahora
          </a>
        </div>
      </div>
    </>
  );
}
