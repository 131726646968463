import { ExpandLess, ExpandMore } from "@mui/icons-material/";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import React from "react";

export function Faq() {
  function Questions(question, answer) {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
      setOpen(!open);
    };
    return (
      <>
        <ListItemButton onClick={handleClick}>
          <ListItemText primary={question}></ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItemText
              primary={answer}
              className="dark-purple"
            ></ListItemText>
          </List>
        </Collapse>
      </>
    );
  }
  return (
    <List>
      {Questions(
        "¿Qué es la cuota inicial? ",
        "La cuota inicial son los ahorros que estás en capacidad de aportar para comprar una parte de la vivienda al inicio del programa. Incluir una cuota inicial va a contribuir a acortar los meses del programa o disminuir el aporte mensual."
      )}
      {Questions(
        "¿De qué está compuesto el pago mensual?",
        "El pago mensual está compuesto del arriendo y la contribución mensual a la compra de la vivienda. El arriendo es un canon mensual que se paga por el alquiler de la vivienda, y la contribución mensual a la compra de la propiedad es un monto fijo que aportas para comprar la vivienda."
      )}
      {Questions(
        "¿Qué es la contribución mensual a la compra de la propiedad?",
        "La contribución mensual a la compra de la propiedad es un valor que se paga adicional al arriendo, y está destinado a adquirir la vivienda. Durante el programa, a medida que vas aportando este valor mes a mes, vas adquiriendo un porcentaje de la vivienda."
      )}
      {Questions(
        "¿Puedo hacer aportes extraordinarios para adquirir la vivienda?",
        "Absolutamente. Puedes hacer los aportes que desees adicionales a la contribución mensual a la compra de la propiedad. Esto contribuirá a acortar los tiempos del programa."
      )}
      {Questions(
        "¿Qué tanto pagaré de arriendo?",
        "El precio de arriendo está fijado por el mercado, la zona y el tipo de inmueble. Imagina que será un valor similar a lo que pagan tus vecinos en arriendo, suponiendo que tienen un inmueble similar."
      )}
      {Questions(
        "¿Cuándo finaliza el programa?",
        "El programa finaliza cuando, con la contribución a la compra de la propiedad, logras adquirir el 20% de la vivienda. En ese momento finaliza el programa, te vuelves elegible para un crédito de vivienda tradicional y te ayudaremos a conseguir con un banco la financiación para que termines de adquirir la propiedad. Durante el programa puedes acelerar los tiempos haciendo contribuciones extraordinarias a la compra de la propiedad."
      )}
      {Questions(
        "¿Cuánto dura el programa?",
        "El programa finaliza cuando adquieres el 20% de la vivienda, pero esto lo debes hacer en un plazo máximo de 5 años. La duración del programa depende de tu capacidad de pago mensual y de si aportas una cuota inicial al inicio del programa."
      )}
      {Questions(
        "¿Puedo adquirir el 20% de la vivienda antes de que finalice el programa?",
        "¡Claro que sí! No queremos que pagues arriendo por más tiempo del que necesitas. Puedes aportar lo que te falta para adquirir el 20% en cualquier momento del programa."
      )}
      {Questions(
        "¿Qué pasa si no puedo seguir en el programa?",
        "Entendemos que la situación puede cambiar. Si no cuentas con los recursos para hacer una compra acelerada de la vivienda, te ofrecemos que nos vendas tu participación adquirida con un descuento para cubrir nuestros gastos. También puedes aportar el valor que ya has aportado para comprar la propiedad como una cuota inicial de una nueva vivienda en nuestro programa."
      )}
      {Questions(
        "¿Si yo ya tengo un porcentaje de la vivienda, debería pagar menos arriendo?",
        "El programa funciona como co-inversión. En la medida que tu adquieres un porcentaje de la vivienda, tienes también derecho a que se te distribuyan los ingresos que genera la propiedad por el arriendo. Estos ingresos los llamamos Rendimiento por Arriendo, y son aportados de forma mensual a la compra de la vivienda."
      )}
      {Questions(
        "¿Por qué cambia el valor del inmueble en el tiempo?",
        "Factores como la inflación contribuyen a que el inmueble se valorice en el tiempo. Invertir en finca raíz también puede contribuir a tener un retorno a largo plazo que se vuelve tangible como una apreciación en el valor del activo, y es un beneficio que tanto tú como nosotros adquirimos como propietarios. El incremento en el precio del inmueble es fijo y se pacta al inicio del programa para que sea transparente para ti."
      )}
      {Questions(
        "¿Cómo aplico al programa?",
        "Puedes aplicar llenando el formulario de aplicación. Lo único que necesitas es responder unas pocas preguntas con las que validaremos tu identidad, tu score crediticio y tu capacidad de pago."
      )}
      {Questions(
        "Quiero participar del programa, pero no me gusta ninguna de las opciones de inmuebles.",
        "Puedes igualmente participar si tienes un inmueble visto que quieras comprar y que cumpla con nuestros requerimientos. En este caso, nosotros adquiriremos el inmueble por ti y tú nos lo comprarás durante el programa."
      )}
      {Questions(
        "¿Hay algún costo para aplicar al programa?",
        "Aplicar al programa no tienen ningún costo. Funciona como un contrato de arriendo a largo plazo con pagos mensuales fijos. Incentivamos que las personas que participan en el programa incluyan una cuota inicial para acelerar los tiempos o bajar la carga mensual a pagar."
      )}
      {Questions(
        "¿Qué documentos necesito para aplicar al programa?",
        "Requerimos una certificación laboral para personas asalariadas (o una certificación de ingresos para personas independientes). También te pediremos que nos des acceso a tu información personal y financiera para hacer un chequeo crediticio y de antecedentes. Esto lo hacemos a través de proveedores certificados para llevar a cabo estos procesos."
      )}
      {Questions(
        "¿Lo que aparece en el simulador representa una oferta formal, y es el valor final a pagar y los tiempos del programa?",
        "No, los valores pueden variar de acuerdo al chequeo crediticio y fecha en que seas aceptado en el programa. Los cálculos que arroja el simulador son solo una aproximación a la oferta que se formaliza por medio de un contrato formal cuando hayas sido aceptado en el programa. La información disponible en el simulador es una estimación que te sirve para entender mejor el programa y calcular aproximadamente cuánto costaría adquirir la vivienda."
      )}
    </List>
  );
}
