import React from 'react';

export function Footer({ nonavpage, successpage }) {
  return (
    <>
      <div
        className={`${
          nonavpage && !successpage ? 'd-none' : 'mt-0'
        } footer`}>
        <div className='footer-container'>
          <div className='info'>
            <div className='icon logo'></div>
            <p>
              Te ayudamos a hacer la transición de arrendatario a
              propietario desde muy baja cuota inicial.
            </p>
          </div>
          <div className='info'>
            <p className='title'>Compañía</p>
            <a href='mailto:info@toperty.co'>
              <p>Nosotros</p>
            </a>
            <a href='mailto:info@toperty.co'>
              <p>Preguntas frecuentes</p>
            </a>
            <a href='mailto:info@toperty.co'>
              <p>Blog</p>
            </a>
            <a href='mailto:info@toperty.co'>
              <p>Portal del usuario</p>
            </a>
            <a href='mailto:info@toperty.co'>
              <p>Portal de inversionistas</p>
            </a>
          </div>
          <div className='info'>
            <p className='title'>Legal</p>
            <a href='/tyc'>
              <p>Términos & Condiciones</p>
            </a>
            <a href='/personal-data'>
              <p>Política de Privacidad</p>
            </a>
          </div>
          <div className='info media'>
            <p className='title'>Contacto</p>
            <a
              href='https://www.tiktok.com/@topertyco'
              className='whatsappfoot'
              target='_blank'
              rel='noreferrer'>
              <div className='media-item'>
                <div className='bg'>
                  <div className='icon'></div>
                </div>
                <div className='text-container'>
                  <h5 className='title'>WhatsApp</h5>
                  <p className='text'>+57 318 750-6913</p>
                </div>
              </div>
            </a>
            <a
              href='https://www.tiktok.com/@topertyco'
              className='emailfoot'
              target='_blank'
              rel='noreferrer'>
              <div className='media-item'>
                <div className='bg'>
                  <div className='icon'></div>
                </div>
                <div className='text-container'>
                  <h5 className='title'>Email</h5>
                  <p className='text'>info@toperty.co</p>
                </div>
              </div>
            </a>
            <a
              href='mailto:info@toperty.co'
              className='addressfoot'
              target='_blank'
              rel='noreferrer'>
              <div className='media-item'>
                <div className='bg'>
                  <div className='icon'></div>
                </div>
                <div className='text-container'>
                  <h5 className='title'>Dirección</h5>
                  <p className='text'>Cll 79b # 5-81, Bogotá, Colombia</p>
                </div>
              </div>
            </a>
          </div>

          <p className='copyright'>
            © Toperty. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </>
  );
}
